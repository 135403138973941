import "./index.css";
import React, { useEffect, useMemo, useState } from "react";
import {
  Menu,
  Avatar,
  Input,
  Card,
  Button,
  Space,
  message,
  Badge,
  Flex,
  Drawer,
} from "antd";
import {
  LeftOutlined,
  RightOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import ChatWindow from "../ChatWindow";
import { fetchAddContact } from "../../api";
import HeaderBar from "../HeaderBar";
import { useContainer } from "../Container";
import useComfirm from "../useComfirm";

const Layout = () => {
  const { state, dispatch } = useContainer();
  const { userData, contactMap, isDesktop } = state;

  const [currentChat, setCurrentChat] = useState("");
  const [toggle, setToggle] = useState(true);
  const [inputValue, setInputValue] = useState();

  useEffect(() => {
    if (!("Notification" in window)) {
      // IE浏览器不支持发送Notification通知!
      message.error("該瀏覽器不支持桌面通知");
      return;
    }
    Notification.requestPermission().then((permission) => {
      // 如果用户同意，就可以直接发送通知
      if (permission === "granted") {
        message.success("您已同意授權桌面通知");
      } else {
        message.error("授權出錯，暫不支持桌面通知");
      }
    });
  }, [isDesktop]);

  const items = useMemo(() => {
    const chatItems = Object.values(contactMap).map((value) => {
      return {
        key: value.content.id,
        icon: (
          <>
            <Badge
              dot={true}
              color={value.content.online ? "green" : "#8c8c8c"}
              offset={[-2, 35]}
            >
              <Avatar
                shape="square"
                size={32}
                style={{
                  verticalAlign: "middle",
                }}
                src={value.content.avatar}
              >
                {value.content.username[0]}
              </Avatar>
            </Badge>
          </>
        ),
        label: (
          <div className="menu-item-label">
            {value.content.username}
            <Badge
              count={55}
              offset={[0, 10]}
              style={{ color: "#fff", border: "none" }}
            ></Badge>
          </div>
        ),
      };
    });

    const text = (inputValue || "").trim();
    if (text) {
      return chatItems.filter((c) => c.label.includes(text));
    } else {
      return chatItems;
    }
  }, [contactMap, inputValue]);

  const onMenuItemChange = (value) => {
    const { key } = value;
    const cur = contactMap[key];
    if (cur) {
      setInputValue("");
      setCurrentChat(cur);
    }
  };

  const [open, contextHolder] = useComfirm();
  const addFriend = () => {
    let phoneNumber;
    open({
      title: "添加好友",
      content: (
        <>
          <p>請輸入好友的電話號碼</p>
          <Input
            size="large"
            placeholder="請輸入"
            value={phoneNumber}
            onChange={(e) => (phoneNumber = e.target.value)}
          ></Input>
        </>
      ),
      onOk: (modal) => {
        if (phoneNumber) {
          fetchAddContact({ phone: inputValue }).then((data) => {
            message.success("添加成功");
          });
        } else {
          modal.destroy();
        }
      },
      okText: "添加",
    });
  };

  const RightRender = (
    <div className="right">
      <Button
        className="toggle-btn"
        // shape="circle"
        type="text"
        ghost={true}
        icon={toggle ? <LeftOutlined /> : <RightOutlined />}
        // size={"small"}
        onClick={() => setToggle(!toggle)}
      />
      <ChatWindow currentFriend={currentChat}></ChatWindow>
    </div>
  );

  const [visible, setVisible] = useState(false);

  const DrawerRight = useMemo(() => {
    return isDesktop ? (
      RightRender
    ) : (
      <Drawer
        title="Basic Drawer"
        onClose={() => setVisible(false)}
        open={visible}
        placement="bottom"
        // height="calc(100vh - 70px)"
        height={'100vh'}
        zIndex={100}
        getContainer={() => document.querySelector('.main-page')}
      >
        {RightRender}
      </Drawer>
    );
  }, [isDesktop, visible]);

  return (
    <div>
      {contextHolder}
      <HeaderBar></HeaderBar>
      <div className="main-page">
        <div className={`left width-change-to-${toggle ? "full" : "zero"}`}>
          <Card
            title={
              <Space
                style={{
                  width: "100%",
                }}
              >
                <Input
                  size="large"
                  placeholder="請輸入"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                ></Input>
                <Button
                  size="large"
                  type="primary"
                  onClick={addFriend}
                  icon={<UserAddOutlined />}
                ></Button>
                <Button size="large" type="primary" onClick={() => setVisible(true)}>
                  drawer
                </Button>
              </Space>
            }
            bordered={false}
          >
            <div className="user-list-menu">
              <Menu
                mode="inline"
                theme="dark"
                items={items}
                onSelect={onMenuItemChange}
              />
            </div>
          </Card>
        </div>

        {DrawerRight}
      </div>
    </div>
  );
};
export default Layout;
