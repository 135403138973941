import { useEffect } from "react";
import { useContainer } from "../Container";

export const useIsDesktop = (props = {}) => {
  const { dispatch, state } = useContainer();
  const { BREAK_POINT = 720 } = props;
  useEffect(() => {
    handleEventListener(true);
    return () => {
      handleEventListener(false);
    };
  }, []);

  const resizeDimension = () => {
    const _breakPoint = BREAK_POINT;
    let isDesktop = window.innerWidth >= _breakPoint;
    dispatch({
      type: "SET_IS_DESKTOP",
      payload: isDesktop,
    });
  };

  const handleEventListener = (data) => {
    if (data) {
      window.addEventListener("resize", resizeDimension);
    } else {
      window.removeEventListener("resize", resizeDimension);
    }
  };

  return state.isDesktop;
};
