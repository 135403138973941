import { Button, Flex, notification } from "antd";

const useComfirm = () => {
  const [api, contextHolder] = notification.useNotification({
    duration: null,
    placement: "top",
    top: 60,
  });

  const open = ({ title, content, onOk, onCancel }) => {
    api.open({
      message: title,
      description: (
        <Flex vertical={true} gap={20}>
          {content}
          <Flex gap={20} justify="flex-end">
            <Button
              type="primary"
              onClick={() => {
                console.log(api);
                onOk?.(api);
              }}
            >
              確定
            </Button>
            <Button
              onClick={() => {
                api.destroy();
                onCancel?.(api);
              }}
            >
              取消
            </Button>
          </Flex>
        </Flex>
      ),
    });
  };

  return [open, contextHolder];
};

export default useComfirm;
