import { message } from "antd";
import axios from "axios";

let _baseURL = "";
if (window.location.host === 'chat.yohann-study.top') {
  _baseURL = "https://chat.yohann-study.top";
} else {
  _baseURL = "http://localhost:8000";
}

let _baseWsURL = "";
if (window.location.protocol === 'https:') {
  _baseWsURL = "wss://chat.yohann-study.top/chat";
} else {
  _baseWsURL = "ws://chat.yohann-study.top/chat";
}

export const baseURL = _baseURL;

const request = axios.create({
  baseURL,
  withCredentials: true,
  validateStatus: (err) => {
    if (err !== 200) {
      message.error(err);
      return false;
    } else {
      return true;
    }
  },
});

let socket;
let timer;

export const GetWebSocket = (id) => {
  if (socket) {
    return socket;
  } else {
    const s = new WebSocket(_baseWsURL);
    socket = s;
    socket.onclose = function (e) {
      console.log(
        "websocket 断开: " + e.code + " " + e.reason + " " + e.wasClean
      );
      if(e.code === 1006 && window.location.protocol === 'https:') {
        window.location.href = "http://chat.yohann-study.top";
      }
      // console.log(e);
    };
    if (!timer) {
      socket.onopen = function (event) {
        timer = setInterval(() => {
          socket.send("beat");
        }, 3000);
      };
    }
    return socket;
  }
};

function fetch(AxiosPromise) {
  return new Promise((resolve, reject) => {
    AxiosPromise.then((result) => {
      if (result.data.success) {
        resolve(result.data.data);
      } else {
        message.error(result.data.message);
        throw result.data.message;
      }
    }).catch((error) => {
      console.error("error:", error);
    });
  });
}

export const fetchRegister = (params) => {
  const { username, phone, password, avatar } = params;
  const promise = request.post("/register", {
    username,
    phone,
    password,
    avatar,
  });
  return fetch(promise);
};

export const fetchLogin = (params) => {
  const { phone, password } = params;
  const promise = request.post("/login", {
    phone,
    password,
  });
  return fetch(promise);
};

export const fetchUpdateUser = (params) => {
  const { id, username, phone, password, avatar } = params;
  const promise = request.post("/updateUser", {
    id,
    username,
    phone,
    password,
    avatar,
  });
  return fetch(promise);
};

export const fetchGetUser = () => {
  const promise = request.get("/getUser");
  return fetch(promise);
};

export const fetchLogout = () => {
  const promise = request.get("/logout");
  return fetch(promise);
};

export const fetchAddContact = ({ phone }) => {
  const promise = request.get("/addContact", {
    params: {
      phone,
    },
  });
  return fetch(promise);
};

export const fetchUpload = (file) => {
  const promise = request.post("/upload", file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return fetch(promise);
};


export const fetchChatHistory = ({ contactId, minimun, size }) => {
  const promise = request.get("/getChatHistory", {
    params: {
      contactId,
      minimun,
      size
    },
  });
  return fetch(promise);
};
