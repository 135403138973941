import "./index.css";
import React, { useState, useRef } from "react";
import { Button, Form, Input, Carousel, Flex, Card, Avatar } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useIsDesktop } from "../useIsDesktop";

const App = ({ onConfirm }) => {
  const carousel = useRef();
  const [loginForm] = Form.useForm();
  const [registerForm] = Form.useForm();
  const isDesktop = useIsDesktop();

  const fishLoop = () => {
    const size = isDesktop ? 100 : 50;
    return (
      <Carousel dots={false} autoplay={true}>
        <div>
          <Avatar
            shape="square"
            src={"https://www.emojiall.com/images/60/apple/1f41f.png"}
            size={size}
          ></Avatar>
        </div>
        <div>
          <Avatar
            shape="square"
            src={"https://www.emojiall.com/images/120/apple/1f420.png"}
            size={size}
          ></Avatar>
        </div>
        <div>
          <Avatar
            shape="square"
            src={"https://www.emojiall.com/images/120/apple/1f421.png"}
            size={size}
          ></Avatar>
        </div>
        <div>
          <Avatar
            shape="square"
            src={"https://www.emojiall.com/images/120/apple/1f988.png"}
            size={size}
          ></Avatar>
        </div>
        <div>
          <Avatar
            shape="square"
            src={"https://www.emojiall.com/images/120/apple/1f42c.png"}
            size={size}
          ></Avatar>
        </div>
      </Carousel>
    );
  };
  const onFinish = (values) => {
    console.log("Success:", values);
    onConfirm(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="login-wrapper">
      <Card>
        <div className="login-box">
          <p style={{ margin: "16px auto", fontSize: 36 }}>事務所</p>
          <Carousel ref={carousel} dots={false}>
            {/* login form */}
            <Form
              layout={"vertical"}
              form={loginForm}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              variant="filled"
            >
              <Form.Item
                style={{ display: "none" }}
                name="type"
                initialValue={"login"}
              ></Form.Item>
              {fishLoop()}
              <Form.Item
                name="phone"
                label="手機號"
                rules={[
                  {
                    required: true,
                    message: "請輸入手機號!",
                  },
                ]}
              >
                <Input placeholder="請輸入手機號" />
              </Form.Item>
              <Form.Item
                name="password"
                label="密碼"
                rules={[
                  {
                    required: true,
                    message: "請輸入密碼！",
                  },
                ]}
              >
                <Input placeholder="請輸入密碼" type="password" />
              </Form.Item>
              <Form.Item>
                <Flex gap={10} justify="space-between">
                  <Button type="primary" htmlType="submit">
                    登錄
                  </Button>
                  <Button
                    type="text"
                    onClick={() => {
                      carousel.current.next();
                    }}
                  >
                    去注冊 <RightOutlined />
                  </Button>
                </Flex>
              </Form.Item>
            </Form>
            {/* register form */}
            <Form
              layout={"vertical"}
              form={registerForm}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              variant="filled"
            >
              <Form.Item
                style={{ display: "none" }}
                name="type"
                initialValue={"register"}
              ></Form.Item>
              <Form.Item
                style={{ display: "none" }}
                name="avatar"
                initialValue={""}
              ></Form.Item>
              <Form.Item
                name="username"
                label="用戶名"
                rules={[
                  {
                    required: true,
                    message: "請輸入用戶名!",
                  },
                ]}
              >
                <Input placeholder="請輸入用戶名" />
              </Form.Item>
              <Form.Item
                name="phone"
                label="手機號"
                rules={[
                  {
                    required: true,
                    message: "請輸入手機號!",
                  },
                ]}
              >
                <Input placeholder="請輸入手機號" />
              </Form.Item>

              <Form.Item
                name="password"
                label="密碼"
                rules={[
                  {
                    required: true,
                    message: "請輸入密碼!",
                  },
                ]}
              >
                <Input placeholder="請輸入密碼" type="password" />
              </Form.Item>
              <Form.Item>
                <Flex gap={10} justify="space-between">
                  <Button
                    type="text"
                    onClick={() => {
                      carousel.current.prev();
                    }}
                  >
                    <LeftOutlined /> 我有賬號，去登錄
                  </Button>
                  <Button type="primary" htmlType="submit">
                    注冊並登錄
                  </Button>
                </Flex>
              </Form.Item>
            </Form>
          </Carousel>
        </div>
      </Card>
    </div>
  );
};
export default App;
