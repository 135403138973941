import "./index.css";
import { BarsOutlined, SunOutlined, MoonOutlined } from "@ant-design/icons";
import { getImageSource, imageUrl, sendNotification } from "../utils/util";
import React, { useState } from "react";
import { Avatar, Image, Dropdown, Input, message, Button, Switch } from "antd";
import useComfirm from "../useComfirm";
import { fetchUpdateUser, fetchUpload } from "../../api";
import { useContainer } from "../Container";

const HeaderBar = () => {
  const [visible, setVisible] = useState(false);
  // const [api, contextHolder] = notification.useNotification();
  const [open, contextHolder] = useComfirm();
  const { state, dispatch } = useContainer();
  const { userData, systemTheme } = state;
  const actions = [
    {
      key: 1,
      label: <div onClick={updateAvatar}>修改頭像</div>,
    },
    {
      key: 2,
      label: <div onClick={updateName}>修改姓名</div>,
    },
    {
      key: 3,
      label: <div onClick={updatePwd}>修改密碼</div>,
    },
    {
      key: 4,
      label: <div onClick={logout}>退出登錄</div>,
    },
  ];

  function updateName() {
    let value;
    open({
      title: "修改姓名",
      content: (
        <>
          <Input
            placeholder="請輸入新的姓名"
            onChange={(e) => (value = e.target.value)}
          ></Input>
        </>
      ),
      onOk: (modal) => {
        updateUserData({ username: value }).then(() => {
          modal.destroy();
        });
      },
    });
  }

  function updatePwd() {
    let value;
    let value2;
    open({
      title: "修改密碼",
      content: (
        <>
          <Input
            type="password"
            placeholder="請輸入新密碼"
            onChange={(e) => (value = e.target.value)}
          ></Input>
          <Input
            type="password"
            placeholder="請確認新密碼"
            onChange={(e) => (value2 = e.target.value)}
          ></Input>
        </>
      ),
      onOk: (modal) => {
        if (value !== value2) {
          message.error("兩次密碼不一致");
        } else {
          updateUserData({ password: value }).then(() => {
            modal.destroy();
          });
        }
      },
    });
  }

  function logout() {
    window.localStorage.clear("userInfo");
    window.localStorage.clear("pwd");
    window.location.reload();
    dispatch({
      type: "USER_DATA",
      payload: {},
    });
  }
  function updateAvatar() {
    getImageSource((input) => {
      console.log(input.files[0]);
      const formData = new FormData();
      formData.append("file", input.files[0]);
      fetchUpload(formData).then((data) => {
        console.log(data);
        updateUserData({ avatar: imageUrl(data.message) });
      });
    });
  }

  function updateUserData(extraParams) {
    const newUserData = {
      ...state.userData,
      ...extraParams,
    };
    return new Promise((resolve) => {
      fetchUpdateUser(newUserData).then(({ data }) => {
        dispatch({
          type: "USER_DATA",
          payload: newUserData,
        });
        resolve(true);
      });
    });
  }

  return (
    <div className="header-wrap" style={{}}>
      <div className="header-userinfo">
        <div>
          <Avatar
            shape="square"
            crossOrigin={"anonymous"}
            size={50}
            style={{
              verticalAlign: "middle",
              cursor: "pointer",
            }}
            src={imageUrl(userData.avatar)}
            onClick={() => setVisible(true)}
          >
            {userData.username[0]}
          </Avatar>
          <Image
            style={{
              display: "none",
            }}
            src={imageUrl(userData.avatar)}
            crossOrigin={"anonymous"}
            preview={{
              visible,
              src: imageUrl(userData.avatar),
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
          <span
            className="username"
            onClick={() => {
              sendNotification({ title: "新消息", body: "收到一條消息" });
            }}
          >
            {userData.username}
          </span>
        </div>
        <div className="header-opt">
          <Dropdown
            trigger={"click"}
            menu={{
              items: actions,
            }}
            placement="bottomLeft"
          >
            <BarsOutlined />
          </Dropdown>
          <span
            onClick={() => {
              dispatch({
                type: "SYSTEM_THEME",
                payload: systemTheme === "dark" ? "light" : "dark",
              });
            }}
          >
            {systemTheme === "dark" ? <MoonOutlined /> : <SunOutlined />}
          </span>
        </div>
      </div>
      {contextHolder}
    </div>
  );
};

export default HeaderBar;
