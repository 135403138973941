import "./index.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { Avatar, Input, Card, Button, Empty } from "antd";
import {
  SmileFilled,
  FileImageFilled,
  CameraFilled,
  SendOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import { GetWebSocket, fetchChatHistory } from "../../api";
import { useContainer } from "../Container";
import MessageBox from "./MessageItem";
import { imageUrl } from "../utils/util";
import useScroll from "../hooks/useScroll";
import useThemeStyle from "../hooks/useThemestyle";

const TextArea = Input.TextArea;

const ChatWindow = ({ currentFriend }) => {
  const { state, dispatch } = useContainer();
  const { userData, chatMap } = state;

  const styles = useThemeStyle((systemTheme) => {
    return {
      backgroundColor: systemTheme === "dark" ? "#141414" : "#ffffff",
      color: "#ffffff" ? "#000000E0" : "#333333",
      borderLeft: `1px solid ${systemTheme === "dark" ? "#303030" : "#f0f0f0"}`,
    };
  });

  const recipientId = useMemo(
    () => currentFriend?.content?.id,
    [currentFriend]
  );
  const senderId = userData.id;

  const [msg, setMsg] = useState("");
  const [showToBottom, setShowToBottom] = useState(false);

  const wsInst = useRef();

  // const { scrollToBottom, showToBottom } = useScroll({ className: ".chat-box", limited: 800 });

  useEffect(() => {
    if (senderId) {
      const ws = GetWebSocket(senderId);
      wsInst.current = ws;
    }
  }, [userData]);

  useEffect(() => {
    console.log("当前联系人：", currentFriend);
    if (currentFriend) {
      // setChatList([]);
      if (chatMap[currentFriend.contactId]) {
        // 当前加载过聊天记录 则不需要再次加载
      } else {
        fetchChatHistory({
          contactId: currentFriend.contactId,
          size: 30,
          minimun: chatMap[currentFriend.contactId]?.minimum,
        })
          .then((data) => {
            const minimum = data[0].id;
            dispatch({
              type: "SET_CHAT_HISTORY",
              payload: {
                type: "unshift",
                contactId: currentFriend.contactId,
                minimum,
                data,
              },
            });
          })
          .catch(() => {
            // setChatList([]);
          });
      }
      const box = document.querySelector(".chat-box");
      if (box) {
        scrollToBottom(false);
        box.addEventListener("scroll", (e) => {
          setShowToBottom(
            box.scrollTop + 800 < box.scrollHeight - box.clientHeight
          );
        });
      }
    }
  }, [currentFriend]);

  useEffect(() => {
    scrollToBottom(true);
  }, [chatMap[currentFriend.contactId]]);

  const scrollToBottom = (isSmooth) => {
    const chatBox = document.querySelector(".chat-box");
    if (chatBox) {
      chatBox.scrollTo({
        top: chatBox.scrollHeight - chatBox.clientHeight,
        left: 0,
        behavior: isSmooth ? "smooth" : "auto", // 滚动的平滑度
      });
    }
  };

  const sendNewMessage = (e) => {
    e.preventDefault();
    if (!e.ctrlKey) {
      wsInst.current.send(
        JSON.stringify({
          content: msg,
          recipient: recipientId,
        })
      );
      setMsg("");
    }
  };
  return (
    <div className="chat-window" style={styles}>
      {currentFriend ? (
        <Card
          title={currentFriend.content.username}
          extra={<span className="more-icon">···</span>}
          bordered={false}
          actions={[
            <div className="msg-input-input-zone">
              <TextArea
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                onPressEnter={sendNewMessage}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.ctrlKey) {
                    console.log("Ctrl + Enter");
                    setMsg((msg) => {
                      return msg + "\n";
                    });
                  }
                }}
                styles={{
                  backgroundColor: "red",
                }}
                placeholder="請輸入文字以發送"
                rows={3}
              />
              <div className="action">
                <div className="icon">
                  <SmileFilled />
                  <CameraFilled />
                  <FileImageFilled />
                </div>
                <Button type="primary" disabled={!msg.trim()} onClick={sendNewMessage}>
                  Send <SendOutlined />
                </Button>
              </div>
            </div>,
          ]}
        >
          {showToBottom && (
            <a onClick={() => scrollToBottom(true)} className="click-to-bottom">
              回到底部
              <DoubleRightOutlined rotate={90} />
            </a>
          )}

          <div className="chat-box">
            {(chatMap[currentFriend.contactId]?.list || []).map((v, index) => {
              return (
                <div
                  key={index}
                  className={`chat-item ${v.sender === userData.id ? "float-right" : "float-left"
                    }`}
                >
                  <Avatar
                    style={{
                      backgroundColor: "#8c8c8c",
                      verticalAlign: "middle",
                      border: "none",
                      borderRadius: "0",
                    }}
                    size={30}
                    shape="square"
                    src={
                      v.sender === userData.id
                        ? imageUrl(userData.avatar)
                        : currentFriend.content.avatar
                    }
                  >
                    {currentFriend.content.username[0]}
                  </Avatar>
                  <MessageBox text={v.content}></MessageBox>
                </div>
              );
            })}
          </div>
        </Card>
      ) : (
        <Empty style={{ paddingTop: "300px" }}></Empty>
      )}
    </div>
  );
};

export default ChatWindow;
