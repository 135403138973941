import { Provider } from "./component/Container";
import EntryComponent from "./page";

function App() {
  return (
    <Provider>
      <EntryComponent></EntryComponent>
    </Provider>
  );
}

export default App;
