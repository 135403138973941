import { useState } from "react";
import { SyncOutlined } from "@ant-design/icons";
const MessageBox = ({ text }) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="message-detail">{text}</div>
      {/* <span>
        <SyncOutlined spin></SyncOutlined>
      </span> */}
    </>
  );
};

export default MessageBox;
