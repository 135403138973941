import { useMemo } from "react";
import { useContainer } from "../Container";

const useThemeStyle = (callback) => {
  const { state } = useContainer();
  const { systemTheme } = state;
  return useMemo(() => {
    return callback(systemTheme)
  }, [systemTheme])
};

export default useThemeStyle;
