import { produce } from "immer";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { imageUrl, sendNotification } from "../utils/util";

export const Ctx = createContext({});

export const useContainer = () => useContext(Ctx);

const reducer = produce((state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SYSTEM_THEME":
      state.systemTheme = payload;
      break;
    case "USER_DATA":
      state.userData = payload;
      break;
    case "MESSAGE_CONTACT":
      state.contactMap[payload.content.id] = payload;
      state.contactMap = { ...state.contactMap };
      break;
    case "SET_CHAT_HISTORY":
      const { type, data, contactId, minimum, messageType } = payload;
      if (state.chatMap[contactId]) {
        if (type === "unshift") {
          state.chatMap[contactId].list = [
            ...data,
            ...state.chatMap[contactId].list,
          ];
        } else if (type === "push") {
          state.chatMap[contactId].list = [
            ...state.chatMap[contactId].list,
            ...data,
          ];
        }
      } else {
        state.chatMap[contactId] = {
          list: data,
          contactId,
          minimum,
        };
      }
      if (messageType === "receive") {
        const contacter = state.contactMap[contactId];
        sendNotification({
          title: "新消息",
          body: data[0].content,
          icon:
            contacter.content.avatar ||
            "https://www.emojiall.com/images/60/apple/1f41f.png",
        });
      }
      state.chatMap = { ...state.chatMap };
      break;
    case "SET_IS_DESKTOP":
      state.isDesktop = payload;
      break;
    default:
      throw new Error();
  }
  return state;
});

export function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    systemTheme: "dark",
    userData: null,
    contactMap: {},
    chatMap: {
      // ['1']: {
      //   list: [],
      //   contactId: '',
      //   minimum: 0
      // }
    },
    sendMessage: {},
    reciptMessage: {},
    isDesktop: true,
  });

  useEffect(() => {
    console.log(
      "%c _STATE_: %o",
      "background: green;font-size: 16px;color: #fff;",
      state
    );
  }, [state]);

  return <Ctx.Provider value={{ state, dispatch }}>{children}</Ctx.Provider>;
}
