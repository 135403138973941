import "./index.css";
import { ConfigProvider, message, theme } from "antd";
import Layout from "../component/Layout";
import Login from "../component/Login";
import { imageUrl, isMobile, sendNotification } from "../component/utils/util";
import { useEffect } from "react";
import { GetWebSocket, fetchGetUser, fetchLogin, fetchRegister } from "../api";
import { useContainer } from "../component/Container";
import { useIsDesktop } from "../component/useIsDesktop";

function EntryComponent() {
  const { state, dispatch } = useContainer();
  const { userData, systemTheme } = state;
  const a = isMobile();

  useEffect(() => {
    if (userData?.id) {
      const ws = GetWebSocket(userData.id);
      ws.addEventListener("message", function (event) {
        const d = JSON.parse(event.data);
        console.log("websocket message：", d);
        if (d.type === "contact") {
          d.content.avatar = imageUrl(d.content.avatar);
          dispatch({
            type: "MESSAGE_CONTACT",
            payload: d,
          });
        } else if (d.type === "message") {
          // 我发出的消息,以及我收到的消息
          dispatch({
            type: "SET_CHAT_HISTORY",
            payload: {
              type: "push",
              messageType: d.sender === userData.id ? "send" : "receive",
              contactId: d.sender === userData.id ? d.contactId : d.sender,
              data: [d],
            },
          });
        }
      });
    }
  }, [userData]);

  useEffect(() => {
    const userInfoJson = window.localStorage.getItem("userInfo");
    const pwd = window.localStorage.getItem("pwd");
    if (userInfoJson) {
      const data = JSON.parse(userInfoJson);
      loginConfirm({
        type: "login",
        phone: data.phone,
        password: pwd,
      });
    }
  }, []);

  useIsDesktop();

  const loginSuccess = (data, pwd) => {
    const _data = data;
    fetchGetUser().then(() => {
      window.localStorage.setItem("userInfo", JSON.stringify(_data));
      window.localStorage.setItem("pwd", pwd);
      dispatch({
        type: "USER_DATA",
        payload: {
          ..._data,
          password: pwd,
        },
      });
    });
  };

  const loginConfirm = (values) => {
    const { type } = values;
    if (type === "login") {
      fetchLogin(values).then((data) => {
        loginSuccess(data, values.password);
      });
    }
    if (type === "register") {
      fetchRegister(values).then(() => {
        fetchLogin(values).then((data) => {
          loginSuccess(data, values.password);
        });
      });
    }
  };
  return (
    <ConfigProvider
      theme={{
        algorithm:
          systemTheme === "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {},
        components: {
          Input: {
            /* 这里是你的组件 token */
          },
        },
      }}
    >
      <div id="react-root" className="App">
        <header className="App-header">
          {state.userData ? (
            <Layout></Layout>
          ) : (
            <Login onConfirm={loginConfirm}></Login>
          )}
        </header>
      </div>
    </ConfigProvider>
  );
}

export default EntryComponent;
