import { message } from "antd";
import { baseURL } from "../../api";

export function isMobile() {
  return "ontouchstart" in document.documentElement;
}

export const imageUrl = (path) => {
  return baseURL + path;
};

export function getImageSource(callback) {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.addEventListener("change", () => {
    callback(input);
  });
  input.click();
}

export function uploadImage({ action, file }) {
  const formData = new FormData();
  // HTML 文件类型 input，由用户选择
  formData.append("file", file);
  var request = new XMLHttpRequest();
  request.open("POST", action);
  request.send(formData);
}

export function sendNotification({ title, body, icon, callback }) {
  function notify() {
    let notification = new Notification(title, {
      icon: icon,
      body: body,
    });
    notification.onclick = () => callback && callback();
    notification.onclose = () => {};
    notification.onerror = (e) => console.log(e);
  }
  if (Notification.permission === "denied") {
    message.warning("您已拒絕桌面通知");
    return;
  }
  if (Notification.permission === "granted") {
    notify();
  } else {
    Notification.requestPermission(function (permission) {
      if (permission === "granted") {
        notify();
      }
    });
  }
}

const paste = (event) => {
  const items = (event.clipboardData || event.originalEvent.clipboardData).items;
  for (let index in items) {
    var item = items[index];
    console.log(item);
    if (item.kind === "file") {
      var blob = item.getAsFile();
      var reader = new FileReader();
      reader.onload = function (event) {
        console.log(event.target.result); // base64 URL
      };
      reader.readAsDataURL(blob);
      break;
    } else if (item.kind === "string") {
      item.getAsString((s) => {
        console.log(s);
      });
      break;
    } else {
    }
  }
};
